import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
	font-family: 'Spoqa Han Sans Neo';
        src: url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');
  }
  body {
    font-family: 'Spoqa Han Sans Neo', sans-serif;
  }
`;

export const InputTitle = styled.h1`
  margin-top: 69px;
  margin-bottom: 22px;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
  font-weight: Regular;
`;

export const InputTextArea = styled.textarea`
  width: 334px;
  height: 440px;
  border-radius: 7px;
  z-index: 2;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 1px solid rgba(1, 67, 190, 0.24);
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
`;


export const SubmitBtn = styled.button`
  width: 334px;
  height: 40px;
  margin-top: 61px;
  margin-bottom: 22px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  background-color: #7D182B;
  color: #ffE8EC;
  font-size: 14px;
  font-weight: Regular;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;

export const InputContentWrap = styled.div`
  z-index: 4;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 30px 0;
`;


