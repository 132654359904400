import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IntroPage from "./view/intro/introPage";
import InputPage from "./view/input/inputPage";
import ResultPage from "./view/result/resultPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/sentence" element={<InputPage />} />
        <Route path="/result" element={<ResultPage />} />
      </Routes>
    </Router>
  );
}

export default App;
