import { createSlice } from "@reduxjs/toolkit";

type RootState = {
  loading: boolean;
  firstVisit: boolean;
};

const initialState: RootState = {
  loading: false,
  firstVisit: false,
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisit: (state, action) => {
      state.firstVisit = action.payload;
    },
  },
});

export const { setLoading, setVisit } = rootSlice.actions;
export default rootSlice.reducer;
