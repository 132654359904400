import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
	font-family: 'Spoqa Han Sans Neo';
        src: url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');
  }
  body {
    font-family: 'Spoqa Han Sans Neo', sans-serif;
  }
`;

export const IntroImg = styled.img`
  width: 156px;
  height: 170px;
  margin-top: 244px;
  margin-bottom: 26px;
`;

export const IntroTitle = styled.h1`
  font-size: 17.33px;
  font-weight: Regular;
  line-height: 1.5;
  text-align: center;
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-bottom: 26px;
`;

export const CorpImg = styled.img`
  position: absolute;
  width: 80px;
  height: 26px;
  bottom: 0;
  margin-bottom: 26px;
`;

export const SubmitBtn = styled.button`
  width: 334px;
  height: 40px;
  margin-top: 61px;
  margin-bottom: 22px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  background-color: #7D182B;
  color: #ffE8EC;
  font-size: 15px;
  font-weight: Medium;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;


