import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
	font-family: 'Spoqa Han Sans Neo';
        src: url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');
  }
  body {
    font-family: 'Spoqa Han Sans Neo', sans-serif;
  }
`;

export const BackBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResultPageHeader = styled.div`
  margin-top: 20px;
  margin-left: 13px;
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const BackbuttonText = styled.h1`
  font-size: 15px;
  font-weight: Medium;
`;

export const ResultPageBackground = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const RatingTitle = styled.h1`
  font-size: 20px;
  font-weight: Medium;
  text-align: center;
  margin-top: 25px;
`;

export const RatingContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 30px 0 30px;
  justify-content: center;
`;

export const RatingBtn = styled.button`
  width: 80px;
  height: 40px;
  margin-left: 10px;
  background-color: #7D182B;
  color: #ffE8EC;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: Medium;
  align-self: center;
`;

export const CopyBtn = styled.button`
  width: 80px;
  height: 40px;
  margin-left: 10px;
  background-color: #7D182B;
  color: #ffE8EC;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: Medium;
  align-self: center;
`;
