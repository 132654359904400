// import styled from "styled-components/macro";
import styled from "styled-components";

export const Logo = styled.img`
  width: 90px;
  height: 13px;
  margin-bottom: 22px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: Medium;
  margin-bottom: 20px;
`;

export const Content = styled.textarea`
  width: 100%;
  height: 450px;
  border-radius: 7px;
  border: 1px solid rgba(1, 67, 190, 0.24);
  font-size: 18px;
  font-weight: 700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 0 30px;
`;
