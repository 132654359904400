import React, { useEffect } from "react";
import { Title, Logo, Content, Container } from "./style";
type Props = {
  title: string;
  logo: string;
  content: string;
};

const ResultListItem = (props: Props) => {
  useEffect(() => {
    console.log(`content : `, props.content);
  }, [props]);

  return (
    <Container>
      <Logo src={props.logo}/>
      <Title>{props.title}</Title>
      <Content>{props.content}</Content>
    </Container>
  );
};

export default ResultListItem;
