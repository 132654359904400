import { configureStore } from "@reduxjs/toolkit";
import rootSlice from "../slices/rootSlice";
import sentenceReducer from "../slices/sentenceSlice";

const store = configureStore({
  reducer: {
    root: rootSlice,
    sentence: sentenceReducer,
  },
  devTools: true,
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
