import React from "react";
import {
  IntroImg,
  IntroTitle,
  IntroContainer,
  CorpImg,
  SubmitBtn,
  GlobalStyle
} from "./introStyle";
import logo from "../../assets/Layer_1.png";
import corp from "../../assets/corp.png";
import { useNavigate } from "react-router-dom";

type Props = {};

const IntroPage = (props: Props) => {
  const navigate = useNavigate();

  const onClick = async () => {
    navigate("/sentence");
  };

  return (
    <IntroContainer>
      <GlobalStyle/>
      <IntroImg src={logo}/>
      <IntroTitle>
본 서비스는 AI 작문심리분석 기술을<br/>
기반으로 (주)센텐솔에서 개발한<br/>
타인 및 자신의 성격 분석결과를<br/>
3초 내에 제공하는 솔루션입니다.
      </IntroTitle>
      <SubmitBtn onClick={(e) => onClick()}>시작하기</SubmitBtn>
      <CorpImg src={corp}/>
    </IntroContainer>
  );
};

export default IntroPage;
