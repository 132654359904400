import React, { useEffect } from "react";
import {
  InputTitle,
  InputTextArea,
  SubmitBtn,
  InputContentWrap,
  GlobalStyle
} from "./inputStyle";
import { postSentence, sentenceChange } from "../../slices/sentenceSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hook";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../slices/rootSlice";
import { AppDispatch } from "../../app/store";

const InputPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentText = useAppSelector((state) => state.sentence.sentence);

  const inputOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(sentenceChange(e.target.value));
  };

  const submitOnClick = async () => {
    if (currentText == "") {
      alert("입력되지 않았습니다. 다시 입력 바랍니다.");
    } else {
      dispatch(setLoading(true));
      const res = await dispatch(postSentence(currentText));
      if (res["payload"]["result"][0]["general_review_result"] == ""){
        dispatch(setLoading(false));
        alert("이해를 못했습니다. 다른 문장을 입력해 주세요.");
      } else {
	navigate("/result");
      }
    }
  };

  return (
    <>
      <InputContentWrap>
        <GlobalStyle/>
        <InputTitle>
상대방에게서 받은 메시지를 복사+붙여넣기 해 보세요.<br/>
(자신이 보낸 메세지나 직접 작성한 글도 가능해요)
        </InputTitle>
        <InputTextArea
          value={currentText}
          onChange={(e) => inputOnChange(e)}
        />
        <SubmitBtn onClick={(e) => submitOnClick()}>입력 완료</SubmitBtn>
      </InputContentWrap>
    </>
  );
};

export default InputPage;
