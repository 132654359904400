import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type AppState = {
  sentence: string;
  rating: boolean;
  result: SentenceRes;
};

type SentenceRes = {
  code: number;
  message: string;
  result: [
    {
      general_review_result: string;
      sentence: {
        index: number;
        word: string;
      };
    }
  ];
};

const initialState: AppState = {
  sentence: "",
  rating: false,
  result: {
    code: 0,
    message: "",
    result: [
      {
        general_review_result: "",
        sentence: {
          index: 0,
          word: "",
        },
      },
    ],
  },
};

export const postSentence = createAsyncThunk(
  "sentence/postSentence",
  async (sentence: string) => {
    const res = await axios.post<SentenceRes>("/api/v1/sentence/report", {
      sentence: sentence,
    });
    return res.data;
  }
);

export const postRating = createAsyncThunk(
  "/api/v1/sentence/star-rate",
  async ({ index, rating }: { index: number; rating: number }) => {
    const res = await axios.post("/api/v1/sentence/star-rate", {
      sentence: { index: index, "star-rate": rating },
    });
  }
);

export const sentenceSlice = createSlice({
  name: "sentence",
  initialState,
  reducers: {
    sentenceChange: (state, action) => {
      state.sentence = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(postSentence.fulfilled, (state, action) => {
      state.result = action.payload;
    });
    builder.addCase(postRating.fulfilled, (state, action) => {
      state.rating = true;
    });
  },
});

export const { sentenceChange } = sentenceSlice.actions;
export default sentenceSlice.reducer;
