import React, { useState, useEffect, useRef } from "react";
import {
  BackBtnWrap,
  BackbuttonText,
  ResultPageHeader,
  ResultPageBackground,
  RatingContainer,
  RatingBtn,
  CopyBtn,
  RatingTitle,
  GlobalStyle
} from "./resultStyle";
import logo from "../../assets/Group.png";
import ResultListItem from "../../components/resultItem/ResultListItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Rating, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hook";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../slices/rootSlice";
import { postRating } from "../../slices/sentenceSlice";

type Props = {};

const ResultPage = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [ratingValue, setRatingValue] = useState<number>(5);
  const result = useAppSelector((state) => state.sentence.result);
  const rating = useAppSelector((state) => state.sentence.rating);

  useEffect(() => {
    if (result?.result[0].general_review_result != "") {
      dispatch(setLoading(false));
    }
  }, [result]);

  const ratingOnClick = async () => {
    if (result.result[0].sentence.index != 0) {
      alert("평가가 완료되었습니다.");
      dispatch(
        postRating({
          index: Number(result.result[0].sentence.index),
          rating: ratingValue * 10,
        })
      );
    }
  };

  const doCopy = () => {
    const text = `${window.location.protocol}//${window.location.hostname}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("클립보드에 복사되었습니다.");
        })
        .catch(() => {
          alert("다시 시도해주세요.");
        });
    } else {
      if (!document.queryCommandSupported("copy")) {
        return alert("복사하기가 지원되지 않는 브라우저입니다.");
      }
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.top = "0";
      textarea.style.left = "0";
      textarea.style.position = "fixed";

      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert("클립보드에 복사되었습니다.");
    }
  };

  const backOnClick = async () => {
    navigate("/sentence");
  };

  return (
    <ResultPageBackground>
      <GlobalStyle/>
      <ResultPageHeader>
        <BackBtnWrap onClick={backOnClick}>
          <ArrowBackIosIcon sx={{ width: "20px", height: "20px" }} />
          <BackbuttonText>뒤로</BackbuttonText>
        </BackBtnWrap>
      </ResultPageHeader>
      <ResultListItem
        title="는 당신의 모습을 응원합니다"
        logo={logo}
        content={result?.result[0].general_review_result}
      />
      <RatingTitle>결과에 만족하셨나요?</RatingTitle>
      <RatingContainer>
        <Box>
          <Rating
            id="star-rating"
            size="large"
            value={ratingValue}
            onChange={(event, newValue) => {
              setRatingValue(newValue as number);
            }}
          />
        </Box>
        <RatingBtn onClick={ratingOnClick}>평가하기</RatingBtn>
        <CopyBtn onClick={() => doCopy()}>링크 복사</CopyBtn>
      </RatingContainer>
    </ResultPageBackground>
  );
};

export default ResultPage;
