import { createGlobalStyle } from "styled-components/macro";

const GlovbalStyle = createGlobalStyle`
    :root {
      --font-size-base: 0.813rem;
      --font-size-md: 1.25rem;
      --font-size-large: 2rem;

      --font-weight-big: 700;
      --font-weight-md: 600;

      --main-color: #FFFFFF;
      --sub-color: #0143BE;
    }

    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    #root{
      max-width: 400px;
      height: 100vh;
      margin: auto;
      background-color:#F3F1F1;
    }
`;

export default GlovbalStyle;
