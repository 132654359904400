import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "../../app/hook";
import styled from "styled-components";

type Props = {};

const ProgressBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 0.2;
  z-index: 5;
`;

const LoadingProgress = (props: Props) => {
  const loadingState = useAppSelector((state) => state.root.loading);

  return loadingState !== false ? (
    <ProgressBackground>
      <CircularProgress />
    </ProgressBackground>
  ) : null;
};

export default LoadingProgress;
